.video-react.video-react-fluid {
  height: 315px;
}

.video-react .video-react-big-play-button {
  left: 45%;
  top: 45%;
}
@media screen and (max-width: 800px) {
  .mb-sm-6 {
    margin-bottom: 6px;
  }
}
