@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;800&display=swap');
.back-blue {
  background-color: #eff3fc;
}
.yellow-h {
  color: #f7ba04;
  font-size: 30px;
}
.h-bold {
  font-size: 40px;
  font-weight: 700;
}
a {
  color: black !important;
}
a:focus {
  color: #f7ba04 !important;
}
a.nav-item.nav-link:focus {
  color: #000 !important;
}

.p-5.about-pad.container-fluid {
  padding: 50px !important;
}
.nav-tabs .nav-link.active {
  border-width: 2px !important;
}
.nav-tabs .nav-link {
  border: 1px solid #076dfd !important;
  border-radius: 20px !important;
}
.nav-tabs {
  border: none !important;
  margin-top: 40px;
}

@media (max-width: 500px) {
  .p-5.about-pad.container-fluid {
    padding: 50px 0 0 0 !important;
  }
  .text-media {
    font-size: 25px !important;
  }
}

.img-org {
  padding: 15px;
  display: block;
  width: 100%;
  border-radius: 25px;
  -webkit-box-shadow: 0px 3px 12px 0px rgba(25, 25, 25, 0.49);
  box-shadow: 0px 3px 12px 0px rgba(25, 25, 25, 0.49);
}

.button-51 {
  background-color: transparent;
  border: 1px solid #266db6;
  box-sizing: border-box;
  color: #00132c;
  font-family: 'Avenir Next LT W01 Bold', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 23px;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-51:hover,
.button-51:active {
  outline: 0;
}

.button-51:hover {
  background-color: transparent;
  cursor: pointer;
}

.button-51:before {
  background-color: #d5edf6;
  content: '';
  height: calc(100% + 3px);
  position: absolute;
  right: -7px;
  top: -9px;
  transition: background-color 300ms ease-in;
  width: 100%;
  z-index: -1;
}

.button-51:hover:before {
  background-color: #6dcff6;
}

@media (min-width: 768px) {
  .button-51 {
    padding: 16px 32px;
  }
}
