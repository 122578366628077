.article-item {
  padding: 10px 10px 20px 10px;
  border: 1px solid rgb(36, 36, 36);
  background: rgb(31, 31, 31);
}

.head-desc {
  font-weight: 700;
}
.img-article {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.row-article {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.row-grey {
  background-color: #262626;
  color: #fff;
}
.back-art {
  padding: 50px;
}
.font-time {
  font-size: 12px;
}
@media (max-width: 500px) {
  .mobile-font {
    font-size: 25px !important;
  }
  .article-item {
    padding: 0 0 20px 0;
  }
  .row-grey {
    padding: 20px !important;
  }
  .article-img {
    width: 100% !important;
    margin-top: 10px;
  }

  .back-art {
    margin: 0 !important;
    padding: 0 !important;
  }
  .head-desc {
    margin: 30px 0 0 !important;
  }
}
.article-img {
  width: 50%;
}
.art-content {
  line-height: 2;
}
