@import url(https://use.typekit.net/qin7miy.css);
@import url(https://use.typekit.net/qin7miy.css);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;800&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.black-color {
  color: #000;
}
.black-color:hover {
  color: #000;
  text-decoration: none;
}
.black-color:focus {
  color: #000 !important;
  text-decoration: none;
}
.black-color:active {
  color: #000 !important;
  text-decoration: none;
}
.blue-line {
  border-top: 3px solid rgba(7, 109, 253);
  width: 50px;
  padding-top: 15px;
}
.img-mail {
  width: 50px;
  position: fixed !important;
  right: 20px;
  bottom: 130px;
  border-radius: 50%;
  z-index: 999;
  box-shadow: 0px 0px 20px 0px rgba(7, 109, 253);
  -webkit-animation-name: pulse_animation;
          animation-name: pulse_animation;
  -webkit-animation-duration: 4000ms;
          animation-duration: 4000ms;
  -webkit-transform-origin: 70% 70%;
          transform-origin: 70% 70%;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.img-phone {
  width: 45px;
  position: fixed !important;
  right: 20px;
  bottom: 76px;
  border-radius: 50%;
  z-index: 999;
  box-shadow: 0px 0px 20px 0px rgba(7, 109, 253);
  -webkit-animation-name: pulse_animation;
          animation-name: pulse_animation;
  -webkit-animation-duration: 4000ms;
          animation-duration: 4000ms;
  -webkit-transform-origin: 70% 70%;
          transform-origin: 70% 70%;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.up {
  width: 50px;
  height: 50px;
  position: fixed !important;
  right: 20px;
  bottom: 190px;
  border-radius: 50%;
  z-index: 999;
  /* -webkit-box-shadow: 0px 0px 20px 0px rgba(7, 109, 253);
  -moz-box-shadow: 0px 0px 20px 0px rgba(7, 109, 253);
  box-shadow: 0px 0px 20px 0px rgba(7, 109, 253); */
  background: rgba(7, 109, 253);
}

@-webkit-keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.text-white:hover {
  text-decoration: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.noShow {
  display: none;
}
/* .topNav {
  display: flex;
} */
@media (max-width: 500px) {
  .img-mail {
    bottom: 200px;
  }
  .img-phone {
    bottom: 140px;
  }
}

body {
  font-family: muli, sans-serif;
}
.background {
  position: relative;
  width: 100vw;
  height: 95vh;
  background: url(/static/media/fera-1.20d17378.jpg);
  background-size: cover;
  background-position: center;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
.flex-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  color: #fff;
}
.flex-right button {
  border: none;
  background: none;
  color: grey;
}
.not-active {
  color: grey;
}
.active-link {
  color: #fff !important;
}
.white-fill {
  border-radius: 50%;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  box-shadow: -1px 0px 2px 1px #fff;
}
.white-fill:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.flex-center {
  text-align: center;
  margin-top: 20vh;
}
.selectStyle {
  border-radius: 10px !important;
  border: 1px solid #b8b8b8 !important;
  background: #fff;
  color: #000 !important;
}

.form-style {
  padding: 10px 20px 50px 20px;
  border-radius: 20px;
  position: relative;
  margin: 0 auto 0 !important;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.form-style .form-control:focus {
  background-color: #353c40;
}
.form-style .form-control {
  background-color: #353c40;
}
.btn-search {
  background-color: #036dfd !important;
  border-radius: 10px !important;
  border: none !important;
  margin-top: 15px;
}
button.btn-search.btn.btn-primary {
  font-weight: 700;
  padding: 7px 10px !important;
}
select {
  background-color: transparent;
  border: none;
  padding: 0.5em;
}

.arrow-down {
  width: 60px;
}

.logo-svg {
  fill: red;
}
.highlight {
  color: #036dfd;
  font-weight: 700;
  font-size: 20px;
}

.search-line {
  border: none !important;
  border-bottom: 1px solid #036dfd !important;
  border-radius: 0%;
  width: 300px;
  margin: auto;
  padding-top: 20px;
  position: relative;
}
.css-6q0nyr-Svg,
.css-1hb7zxy-IndicatorsContainer {
  display: -webkit-flex !important;
  display: flex !important;
}

.search-line::after {
  content: url(/static/media/search.8eaca223.svg);
  width: 20px !important;
  position: absolute;
  right: -30px;
  top: 30px;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.search-line:focus {
  outline: none !important;
  /* transform: translateY(10px); */
  width: 400px;
  color: #fff;
}
.search-line::after {
  color: #036dfd;
  width: 10px;
}

@media (max-width: 500px) {
  .nav-logo-lang {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .white-fill {
    width: 50px;
  }
  .search-line {
    width: 80%;
  }
  .transitions-item {
    font-size: 20px !important;
  }

  .background {
    height: 100vh;
  }
  .flex-center {
    text-align: center;
    margin-top: 10vh;
  }
  .form-style {
    /* padding-top: 100px; */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
  }
}

.back-blue {
  background-color: #eff3fc;
}
.yellow-h {
  color: #f7ba04;
  font-size: 30px;
}
.h-bold {
  font-size: 40px;
  font-weight: 700;
}
a {
  color: black !important;
}
a:focus {
  color: #f7ba04 !important;
}
a.nav-item.nav-link:focus {
  color: #000 !important;
}

@media (max-width: 500px) {
  .p-5.about-pad.container-fluid {
    padding: 50px 0 0 0 !important;
  }
}

.style-btn-adv {
  background-color: #06439e;
  border-radius: 20px;
  border: none;
  width: 40%;
  margin: auto;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  box-shadow: 2px 2px 5px 1px #03070c;
}

.yellow-back {
    background-color: #F7BA04;
    color: #000;
}
.img-app {
    height: 330px;
    width : 100%;
    object-fit: cover;
    object-position: top;
    opacity: 0.5;  
}
.center-app {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0 !important ;
}
.font-25-700 {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
}
.col-no-gap {
    padding: 0 !important;
}
@media (max-width: 500px) {
    div.text-left.col-lg-5.col-md-6.col-sm-12 {
        padding: 0 !important;
    }
    .font-25-700 {
       padding: 20px;
    }
}
.icon-adv {
    width: 30px;
    margin-right: 20px;
    background: #076DFD;
    padding: 5px;
}
.header-6 {
    font-weight: bold;
    font-size: 15px;
    color: #000;
}
.margin-left {
    margin-left: 70px;
}
.advantage p {
    color: #6F6F6F;
    font-weight: 600;
    
    font-size: 12px;
}
.row-column {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
   
}

.text-400 {
    font-weight: 200;
}
.flex-between {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}


.video-react.video-react-fluid {
  height: 315px;
}

.video-react .video-react-big-play-button {
  left: 45%;
  top: 45%;
}
@media screen and (max-width: 800px) {
  .mb-sm-6 {
    margin-bottom: 6px;
  }
}

.back-blue {
  background-color: #eff3fc;
}
.yellow-h {
  color: #f7ba04;
  font-size: 30px;
}
.h-bold {
  font-size: 40px;
  font-weight: 700;
}
a {
  color: black !important;
}
a:focus {
  color: #f7ba04 !important;
}
a.nav-item.nav-link:focus {
  color: #000 !important;
}

.p-5.about-pad.container-fluid {
  padding: 50px !important;
}
.nav-tabs .nav-link.active {
  border-width: 2px !important;
}
.nav-tabs .nav-link {
  border: 1px solid #076dfd !important;
  border-radius: 20px !important;
}
.nav-tabs {
  border: none !important;
  margin-top: 40px;
}

@media (max-width: 500px) {
  .p-5.about-pad.container-fluid {
    padding: 50px 0 0 0 !important;
  }
  .text-media {
    font-size: 25px !important;
  }
}

.img-org {
  padding: 15px;
  display: block;
  width: 100%;
  border-radius: 25px;
  box-shadow: 0px 3px 12px 0px rgba(25, 25, 25, 0.49);
}

.button-51 {
  background-color: transparent;
  border: 1px solid #266db6;
  box-sizing: border-box;
  color: #00132c;
  font-family: 'Avenir Next LT W01 Bold', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 23px;
  position: relative;
  text-decoration: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-51:hover,
.button-51:active {
  outline: 0;
}

.button-51:hover {
  background-color: transparent;
  cursor: pointer;
}

.button-51:before {
  background-color: #d5edf6;
  content: '';
  height: calc(100% + 3px);
  position: absolute;
  right: -7px;
  top: -9px;
  transition: background-color 300ms ease-in;
  width: 100%;
  z-index: -1;
}

.button-51:hover:before {
  background-color: #6dcff6;
}

@media (min-width: 768px) {
  .button-51 {
    padding: 16px 32px;
  }
}

.footer-item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    border-right: 1px solid rgba(143, 143, 143, 0.1);
    margin-bottom: 50px !important;
}
.footer-item:last-child {
    border-right: none;
}
.font-footer {
    font-size: 16px;
}
.font-footer a  {
    color: #fff !important;
    text-decoration: none !important;
}
.line {
    border-bottom:  1px solid rgba(143, 143, 143, 0.1);
    border-top:  1px solid rgba(143, 143, 143, 0.1);

}
.icon-fill {
   -webkit-filter: invert(100%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(29%);
           filter: invert(100%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(29%); 
}
.opacity{
    opacity: 0.4;
    transition: all 0.4s ease-out; 
}
.opacity:hover {
    opacity: 1;
    transition: all 0.4s ease-out;  
}
.col-sm-12 {
    width: auto !important;
}
@media (max-width: 500px) {
    .footer-item {
        border: none;
        display: -webkit-flex;
        display: flex;
        /* flex-direction: column; */
        -webkit-justify-content: center;
                justify-content: center;
        margin-bottom: 20px;
    }
  
    
}
body {
  font-family: 'Muli', Arial, Helvetica, sans-serif;
}
.white {
  color: #fff !important;
}
.nav-style {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.link-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.link-nav:focus,
.nav-item:focus {
  color: #fff !important;
}

.ing-small {
  color: #fff !important;
}
.navbar-nav {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center !important;
          justify-content: center !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}
.nav-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.bottomNav {
  position: fixed !important;
  bottom: 0 !important;
  background: #062bb2 !important;
  z-index: 999;
  width: 100%;
  margin: 0 auto !important;
}
.bottomNav.navbar.navbar-expand-lg.navbar-light {
  padding: 10px 0 10px 0;
}

.nav-item {
  margin-left: 20px;
  font-weight: 600;
  font-size: 10px;
}
.navbar-light .navbar-nav .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.navbar-light .navbar-toggler-icon {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.btn-link {
  border: none;
  background: none;
  color: #fff !important;
  font-weight: bold !important;
}
.icon-white {
  background: #fff;
  border-radius: 50%;
  width: 20px;
}
.login-form {
  background: #f2f5fb;
  padding: 40px 20px;
}
.login-form .form-control {
  box-shadow: 0 5px 10px #e9ecef;
}
.calc_form .modal-content,
.calc_form {
  background: #f2f5fb !important;
  box-shadow: none;
  border: none !important;
}
.modal-content {
  background: #f2f5fb !important;
  /* padding: 40px 40px; */
  border-radius: 20px !important;
  border: none !important ;
}
.no-shadow .modal-content {
  box-shadow: none !important;
}
.modal-header,
.modal-footer {
  border: none !important;
}
.modal-dialog {
  border: none !important;
}
.btn {
  padding: 0 !important;
  border: none !important;
}
.btn-form {
  border-radius: 20px !important;
  padding: 7px 40px !important;
}
.yellow-back {
  background-color: #036dfd !important;
}
.grey-back {
  background-color: #222934 !important;
}
.flex-form {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.close {
  font-weight: 500 !important;
}
.line-bottom {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 50px;
}
.icon-calc {
  width: 30px;
}
.calc_form .form-control {
  height: 50px;
  box-shadow: 0 5px 10px #e9ecef;
}

.calc_form .modal-content {
  padding: 0;
}
.calc_form {
  font-weight: bold;
}
.calc-output {
  text-align: center;
  margin-top: 40px;
  border: 1px solid #036dfd;
  border-radius: 10px;
  padding: 20px;
}
.border-right-calc {
  border-right: 1px solid #036dfd;
}
.btn-nav {
  padding: 10px;
}
.button-nav {
  padding: 5px 10px !important;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 1px #03070c;
  background: #1d4dff;
}
.img-small {
  width: 20px;
  margin: auto;
}
@media (max-width: 500px) {
  .img-small {
    width: 15px !important;
  }
  .nav-item {
    font-size: 10px;
    margin-left: 10px;
  }
  .nav-item:last-child {
    margin-right: 10px;
  }
  .nav-style {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .mob-nav {
    display: -webkit-flex;
    display: flex;
    /* flex-wrap: wrap; */
    -webkit-justify-content: space-between;
            justify-content: space-between;
    text-align: center;
  }
  a.nav-item.nav-link {
    margin-bottom: 10px;
  }
  .mag-link {
    margin-bottom: 10px;
  }
}

.p-12 {
  font-size: 12px;
}
.p-12-link {
  text-decoration: underline;
  font-size: 12px;
}
.hover-link {
  text-decoration: underline;
}
.hover-link:hover {
  cursor: pointer;
}

.input-style {
    border-radius: 50px;
    padding: 0 20px;
}

/*  */
@media( max-width: 600px) {
    .line {
        display: none;
    }
}
.article-item {
  padding: 10px 10px 20px 10px;
  border: 1px solid rgb(36, 36, 36);
  background: rgb(31, 31, 31);
}

.head-desc {
  font-weight: 700;
}
.img-article {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.row-article {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.row-grey {
  background-color: #262626;
  color: #fff;
}
.back-art {
  padding: 50px;
}
.font-time {
  font-size: 12px;
}
@media (max-width: 500px) {
  .mobile-font {
    font-size: 25px !important;
  }
  .article-item {
    padding: 0 0 20px 0;
  }
  .row-grey {
    padding: 20px !important;
  }
  .article-img {
    width: 100% !important;
    margin-top: 10px;
  }

  .back-art {
    margin: 0 !important;
    padding: 0 !important;
  }
  .head-desc {
    margin: 30px 0 0 !important;
  }
}
.article-img {
  width: 50%;
}
.art-content {
  line-height: 2;
}

.modal-details .modal-content {
  padding: 0;
}
.modal-details .modal-dialog {
  min-width: 100%;
}
.cancel-btn {
  background-color: #f7ba04 !important;
  padding: 10px !important;
}
.row-modal .col:first-child {
  font-weight: 700;
}
.price-details {
  color: #0e32ff;
  font-weight: bold;
  font-size: 30px;
}
.btn.btn-cancel-modal {
  padding: 5px 40px !important;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 3px 8px 0px rgb(0 0 1 / 20%);
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  background: #262626;
}
.header-details {
  font-size: 40px;
  font-weight: 700;
}
.weight-thin {
  font-weight: 400;
}
.current-bid {
  background: #076dfd;
  color: #fff;
  font-weight: 700;
}
.carousel .slide img {
  height: 312px;
  object-fit: cover;
}
@media (max-width: 500px) {
  .row-modal .col {
    font-size: 10px;
  }
  .price-details,
  .name-details {
    font-size: 20px;
  }
  .row-modal {
    margin-bottom: 20px;
  }
  .carousel .slide img {
    height: 312px;
    object-fit: cover;
  }
}

.row-calcs {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.img-bank {
  width: 70%;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 20%;
  padding: 10px;
}
.img-bank-last {
  width: 70%;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 20%;
  padding: 15px 10px;
}

@media (max-width: 800px) {
  .img-bank {
    width: 100%;
    padding: 1px !important;
  }
  .img-bank-last {
    width: 100%;
    padding: 5px 1px !important;
  }
}
/* }
@media (max-width: 500px) {
  .img-bank {
    width: 100%;
  }
  .img-bank-last {
    width: 100%;
    padding: 5px 1px;
  }
} */
/* .row-calcs:last-child {
  padding: 30px 10px;
} */

button.btn-modal {
    padding: 5px 20px !important;
    border-radius: 20px !important;
}
.text-tel {
    text-decoration: none !important;
}
.blue-color {
  color: #076dfd;
}
.grey-btn {
  background-color: #222833 !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}
.orange-btn {
  background-color: #f7ba04 !important;
  border-radius: 10px !important;
}
.orange-btn.btn {
  color: #000 !important;
  font-weight: 700;
}

.btn-shop {
  border: none !important;
  color: #fff !important;
  padding: 15px 70px !important;
  box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2) !important;
}

.heading-style h3 {
  font-size: 50px;
  font-weight: 900;
  color: #076dfd;
}
.heading-style h6 {
  font-size: 25px;
  font-weight: 900;
  color: #fff;
}
.flex-style {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-top: 60px;
}

.weight-bold {
  font-weight: bold;
}
.margin-no {
  margin: 0 !important;
}
.card-style {
  padding: 20px;
  background: none !important;
  color: #fff !important;
}

.white-icon {
  width: 22px;
  margin-right: 10px;
}
.a-icon-1 {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 4px 7px;
  margin-right: 10px;
  font-weight: 700;
}
.color-yellow {
  color: #f7ba04;
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
.small-grey {
  font-weight: bold;
  font-size: 12px;
}
.icon-small-2 {
  width: 16px;
  margin-right: 10px;
}
.align-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 10px;
}
.center-items {
  font-size: 10px;
}
.center-items-small {
  font-size: 10px;
}
.a-icon-2 {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 2px 4px;
}
.img-feature {
  object-fit: cover;
  height: 200px;
}

.card-height {
  height: 710px;
}
.flex-card {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.no-border-modal {
  background: #f2f5fb;
}
.no-border-modal .modal-content {
  border: none !important;
}

.adv-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* border: 1px solid rgb(225, 225, 225); */
  border-radius: 0;
  padding: 20px;
  background-color: #fff;
}
.short-border {
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.short-border:last-child {
  border: none;
  margin-bottom: 0;
}
.bit-btn {
  background-color: #e24700;
  padding: 5px 40px;
}
.font-adv {
  font-size: 15px;
  font-weight: 700;
}
.font-bid-price {
  font-weight: 700;
  font-size: 17px;
  color: #e24700;
}
div.adv-box.p-5.col-lg-3.col-md-12.col-sm-12 {
  padding: 0 !important;
}
.container-fluid {
  padding: 0 !important;
}
@media (max-width: 500px) {
  div.adv-box.p-5.col-lg-3.col-md-12.col-sm-12 {
    padding: 0 !important;
  }
  div.col-lg-9.col-md-12.col-sm-12 {
    padding: 0 !important;
  }
}

@media (max-width: 1024px) {
  .short-border {
    border-bottom: none;
  }
}

.btn-item {
  border-radius: 20px;
  border: none;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 1, 0.2);
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

.buy-now {
  background-color: #fff;
  color: #000;
  padding: 5px 45px;
}
.swap-btn {
  background-color: #62a6a4;
  padding: 5px 20px;
  color: #fff;
}
.auction-btn {
  background-color: #076dfd;
  padding: 5px 40px;
  color: #fff !important;
}
.auction-btn:hover {
  color: #000 !important;
  text-decoration: none;
}
.auction-btn-bet {
  color: #ffffff;
  padding: 5px 40px;
  background: #000;
}
.price-input {
  border: 1px solid #8e8e8e;
  background: #ededed;
  border-radius: 5px;
  /* padding: 2px 10px; */
  text-align: center;
  margin-top: 10px;
}
.a-icon {
  border: 2px solid #000;
  border-radius: 50%;
  padding: 2px 7px;
  margin-right: 10px;
  color: #000;
  font-weight: bold;
  font-size: 10px;
}
.icon-small {
  width: 22px;
  margin-right: 10px;
}
.center-items {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.float-pos {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.price-p {
  width: 40%;
  border: 1px solid #b6b6b6;
  border-radius: 4px !important;
}
.tel {
  color: #e24800;
}
.flex-pos {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.flex-end {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.btn-bid {
  border-radius: 20px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}
button.btn-bid.btn.btn-primary {
  padding: 5px 35px !important;
}
.border-style {
  border-radius: 10px !important;
}
.align-center {
  -webkit-align-items: center;
          align-items: center;
}
.start-price {
  font-size: 13px;
}
.start-price {
  font-weight: bold;
}
.btns-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.car-item-style {
  border: none;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0px 40px 5px #2f2c2b;
}
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
@media (max-width: 500px) {
  div.col-lg-3.col-md-6.col-sm-12 {
    padding: 0 !important;
  }
  div.car-item-style.align-center.row {
    padding: 0 !important;
  }
  /* .card-style {
        padding: 0 !important;
    } */
}

.invert-white {
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
.style-spacing {
  letter-spacing: 1px;
}

.selectStyle.form-control {
  background: none !important;
}
.select-arrow {
  display: none !important;
}
.css-yk16xz-control {
  background: none !important;
  border: none !important;
}
.css-yk16xz-control {
  background: none !important;
  border-radius: 20px;
}
.css-g1d714-ValueContaine {
  padding: 5px !important;
}
input#react-select-2-input {
  width: 100% !important;
}
.padding-filter {
  padding-top: 40px;
}

@media (max-width: 500px) {
  .optionStyle {
    width: 300px !important;
  }
  .padding-filter {
    padding-top: 20px;
  }
}

.p-12 {
  font-size: 12px;
}
.p-12-link {
  text-decoration: underline;
  font-size: 12px;
}
.PhoneInputInput {
  border-radius: 20px;
  padding: 5px 20px;
  border: 1px solid #ced4da;
}

.profile {
    height: 100vh;
    background: #262626;
    color: #fff;
}
