body {
  font-family: 'Muli', Arial, Helvetica, sans-serif;
}
.white {
  color: #fff !important;
}
.nav-style {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.link-nav {
  display: flex;
  flex-direction: column;
}
.link-nav:focus,
.nav-item:focus {
  color: #fff !important;
}

.ing-small {
  color: #fff !important;
}
.navbar-nav {
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: center !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}
.nav-wrap {
  flex-wrap: wrap;
}
.bottomNav {
  position: fixed !important;
  bottom: 0 !important;
  background: #062bb2 !important;
  z-index: 999;
  width: 100%;
  margin: 0 auto !important;
}
.bottomNav.navbar.navbar-expand-lg.navbar-light {
  padding: 10px 0 10px 0;
}

.nav-item {
  margin-left: 20px;
  font-weight: 600;
  font-size: 10px;
}
.navbar-light .navbar-nav .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.navbar-light .navbar-toggler-icon {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.btn-link {
  border: none;
  background: none;
  color: #fff !important;
  font-weight: bold !important;
}
.icon-white {
  background: #fff;
  border-radius: 50%;
  width: 20px;
}
.login-form {
  background: #f2f5fb;
  padding: 40px 20px;
}
.login-form .form-control {
  box-shadow: 0 5px 10px #e9ecef;
}
.calc_form .modal-content,
.calc_form {
  background: #f2f5fb !important;
  box-shadow: none;
  border: none !important;
}
.modal-content {
  background: #f2f5fb !important;
  /* padding: 40px 40px; */
  border-radius: 20px !important;
  border: none !important ;
}
.no-shadow .modal-content {
  box-shadow: none !important;
}
.modal-header,
.modal-footer {
  border: none !important;
}
.modal-dialog {
  border: none !important;
}
.btn {
  padding: 0 !important;
  border: none !important;
}
.btn-form {
  border-radius: 20px !important;
  padding: 7px 40px !important;
}
.yellow-back {
  background-color: #036dfd !important;
}
.grey-back {
  background-color: #222934 !important;
}
.flex-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close {
  font-weight: 500 !important;
}
.line-bottom {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 50px;
}
.icon-calc {
  width: 30px;
}
.calc_form .form-control {
  height: 50px;
  box-shadow: 0 5px 10px #e9ecef;
}

.calc_form .modal-content {
  padding: 0;
}
.calc_form {
  font-weight: bold;
}
.calc-output {
  text-align: center;
  margin-top: 40px;
  border: 1px solid #036dfd;
  border-radius: 10px;
  padding: 20px;
}
.border-right-calc {
  border-right: 1px solid #036dfd;
}
.btn-nav {
  padding: 10px;
}
.button-nav {
  padding: 5px 10px !important;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 1px #03070c;
  background: #1d4dff;
}
.img-small {
  width: 20px;
  margin: auto;
}
@media (max-width: 500px) {
  .img-small {
    width: 15px !important;
  }
  .nav-item {
    font-size: 10px;
    margin-left: 10px;
  }
  .nav-item:last-child {
    margin-right: 10px;
  }
  .nav-style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .mob-nav {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    text-align: center;
  }
  a.nav-item.nav-link {
    margin-bottom: 10px;
  }
  .mag-link {
    margin-bottom: 10px;
  }
}
