.selectStyle.form-control {
  background: none !important;
}
.select-arrow {
  display: none !important;
}
.css-yk16xz-control {
  background: none !important;
  border: none !important;
}
.css-yk16xz-control {
  background: none !important;
  border-radius: 20px;
}
.css-g1d714-ValueContaine {
  padding: 5px !important;
}
input#react-select-2-input {
  width: 100% !important;
}
.padding-filter {
  padding-top: 40px;
}

@media (max-width: 500px) {
  .optionStyle {
    width: 300px !important;
  }
  .padding-filter {
    padding-top: 20px;
  }
}
