.row-calcs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-bank {
  width: 70%;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 20%;
  padding: 10px;
}
.img-bank-last {
  width: 70%;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 20%;
  padding: 15px 10px;
}

@media (max-width: 800px) {
  .img-bank {
    width: 100%;
    padding: 1px !important;
  }
  .img-bank-last {
    width: 100%;
    padding: 5px 1px !important;
  }
}
/* }
@media (max-width: 500px) {
  .img-bank {
    width: 100%;
  }
  .img-bank-last {
    width: 100%;
    padding: 5px 1px;
  }
} */
/* .row-calcs:last-child {
  padding: 30px 10px;
} */
