.footer-item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-right: 1px solid rgba(143, 143, 143, 0.1);
    margin-bottom: 50px !important;
}
.footer-item:last-child {
    border-right: none;
}
.font-footer {
    font-size: 16px;
}
.font-footer a  {
    color: #fff !important;
    text-decoration: none !important;
}
.line {
    border-bottom:  1px solid rgba(143, 143, 143, 0.1);
    border-top:  1px solid rgba(143, 143, 143, 0.1);

}
.icon-fill {
   filter: invert(100%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(29%); 
}
.opacity{
    opacity: 0.4;
    transition: all 0.4s ease-out; 
}
.opacity:hover {
    opacity: 1;
    transition: all 0.4s ease-out;  
}
.col-sm-12 {
    width: auto !important;
}
@media (max-width: 500px) {
    .footer-item {
        border: none;
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        margin-bottom: 20px;
    }
  
    
}