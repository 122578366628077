.blue-color {
  color: #076dfd;
}
.grey-btn {
  background-color: #222833 !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}
.orange-btn {
  background-color: #f7ba04 !important;
  border-radius: 10px !important;
}
.orange-btn.btn {
  color: #000 !important;
  font-weight: 700;
}

.btn-shop {
  border: none !important;
  color: #fff !important;
  padding: 15px 70px !important;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2) !important;
}

.heading-style h3 {
  font-size: 50px;
  font-weight: 900;
  color: #076dfd;
}
.heading-style h6 {
  font-size: 25px;
  font-weight: 900;
  color: #fff;
}
.flex-style {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 60px;
}

.weight-bold {
  font-weight: bold;
}
.margin-no {
  margin: 0 !important;
}
.card-style {
  padding: 20px;
  background: none !important;
  color: #fff !important;
}

.white-icon {
  width: 22px;
  margin-right: 10px;
}
.a-icon-1 {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 4px 7px;
  margin-right: 10px;
  font-weight: 700;
}
.color-yellow {
  color: #f7ba04;
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
.small-grey {
  font-weight: bold;
  font-size: 12px;
}
.icon-small-2 {
  width: 16px;
  margin-right: 10px;
}
.align-center {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.center-items {
  font-size: 10px;
}
.center-items-small {
  font-size: 10px;
}
.a-icon-2 {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 2px 4px;
}
.img-feature {
  object-fit: cover;
  height: 200px;
}

.card-height {
  height: 710px;
}
.flex-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.no-border-modal {
  background: #f2f5fb;
}
.no-border-modal .modal-content {
  border: none !important;
}

.adv-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid rgb(225, 225, 225); */
  border-radius: 0;
  padding: 20px;
  background-color: #fff;
}
.short-border {
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.short-border:last-child {
  border: none;
  margin-bottom: 0;
}
.bit-btn {
  background-color: #e24700;
  padding: 5px 40px;
}
.font-adv {
  font-size: 15px;
  font-weight: 700;
}
.font-bid-price {
  font-weight: 700;
  font-size: 17px;
  color: #e24700;
}
div.adv-box.p-5.col-lg-3.col-md-12.col-sm-12 {
  padding: 0 !important;
}
.container-fluid {
  padding: 0 !important;
}
@media (max-width: 500px) {
  div.adv-box.p-5.col-lg-3.col-md-12.col-sm-12 {
    padding: 0 !important;
  }
  div.col-lg-9.col-md-12.col-sm-12 {
    padding: 0 !important;
  }
}

@media (max-width: 1024px) {
  .short-border {
    border-bottom: none;
  }
}
